body {
  margin: 0;
  /* font-family: 'Manrope', sans-serif; */
  font-family: 'Montserrat', sans-serif;
/* font-family: 'Mr Dafoe', cursive; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
  border: none !important;
  border-width: 0 !important;
}
